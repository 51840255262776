/* *{
  border: 1px solid red;
} */
.no-select {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* border: 1px solid saddlebrown; */
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  cursor: url("./assets/svgs/pointer.svg"), auto;
  overflow: hidden;
  font-family: "SF-Pro", "Inter", sans-serif;
}

.hover-cursor {
  cursor: url("./assets/svgs/pointinghand.svg"), auto;
}

.edit-cursor {
  cursor: url("./assets/svgs/ibeam.svg"), auto;
}

.main-background {
  background-image: url(./assets/images/hills.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dock-glass {
  background: rgba(40, 40, 40, 0.7);
  backdrop-filter: blur(60px) saturate(210%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 60px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px rgba(0, 0, 0, 0.55);
}
.app-glass {
  background: rgba(40, 40, 40, 0.7);
  backdrop-filter: blur(60px) saturate(210%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(60px) saturate(210%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px rgba(0, 0, 0, 0.55);
}

.white-glass {
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(60px) saturate(210%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(60px) saturate(210%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px rgba(0, 0, 0, 0.55);
}

.icon {
  transition-duration: 300ms;
}

.icon:hover {
  transform: translateY(-0.25rem) scaleX(1.5) scaleY(1.5);
  margin: 0 1em;
  transition-duration: 300ms;
}

.window--minimized {
  top: 100vh !important;
  transform: scale(0) !important;
  -webkit-transform-origin: center bottom !important;
  -moz-transform-origin: center bottom !important;
  transform-origin: center bottom !important;
  opacity: 0;
  pointer-events: none !important;
  transition: all 0.35s !important;
}

.window--maximized {
  border-radius: 0px;
  width: 100% !important;
  height: 100% !important;
  /* width: 100vw !important;
  height: 100vh !important; */
  z-index: 100 !important;
  transition: all 0.1s, z-index 0ms;
}

.window--maximized:after {
  border-radius: 0 !important;
}

.window--maximized
  .window__handler
  .window__controls
  .window__maximize::before {
  top: 50%;
  left: calc(50% - 4px);
}

.window--maximized .window__handler .window__controls .window__maximize::after {
  top: calc(50% - 4px);
  left: 50%;
}

.window--maximized .window__handler:before,
.window--maximized .window__handler:after {
  border-radius: 0px !important;
}

.window--maximized[data-windowBackdrop="true"]:before {
  border-radius: 0px !important;
}

.window--minimized {
  top: 100vh !important;
  transform: scale(0) !important;
  -webkit-transform-origin: center bottom !important;
  -moz-transform-origin: center bottom !important;
  transform-origin: center bottom !important;
  opacity: 0;
  pointer-events: none !important;
  transition: all 0.35s !important;
}

.window--closed {
  display: none;
  transition: all 0.1s, top 0.1s ease-in-out, left 0.1s ease-in-out,
    transform 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out,
    z-index 0ms, box-shadow 0ms;
}

.window {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: hidden;
  position: absolute;
  display: block;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top: 50%; */
  transition: all 0.1s, top 0.1s ease-in-out, left 0.1s ease-in-out,
    transform 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out,
    z-index 0ms, box-shadow 0ms;
  z-index: 0;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  transform-origin: center center;
  /* -webkit-transform: none;
  -moz-transform: none; */
  /* transform: none; */
  border-radius: 10px;
  /* background: #1e1e1f; */
  min-width: 400px;
  min-height: 200px;

  top: 50%;
  overflow: hidden;
  z-index: 1;
}

.window:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  z-index: 999999;
  pointer-events: none;
}

.window-control:hover img,
.window-control:hover svg {
  display: block;
}

.selected {
  background-color: red;
}

.note-item:hover div p {
  color: white;
}

.note-item:hover div h3 {
  color: white;
}

.editor-main {
  user-select: text !important;
  -webkit-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.notes-window--minimized {
  top: 100vh !important;
  transform: scale(0) !important;
  -webkit-transform-origin: center bottom !important;
  -moz-transform-origin: center bottom !important;
  transform-origin: center bottom !important;
  opacity: 0;
  pointer-events: none !important;
  transition: all 0.35s !important;
}

.notes-window--maximized {
  border-radius: 0px;
  /* width: 100vw !important;
  height: 100vh !important; */
  width: 100% !important;
  height: 100% !important;

  transition: all 0.1s, z-index 0ms;
  z-index: 100 !important;
}

.notes-window--maximized:after {
  border-radius: 0 !important;
}

.notes-window--maximized
  .window__handler
  .window__controls
  .window__maximize::before {
  top: 50%;
  left: calc(50% - 4px);
}

.notes-window--maximized
  .window__handler
  .window__controls
  .window__maximize::after {
  top: calc(50% - 4px);
  left: 50%;
}

.notes-window--maximized .window__handler:before,
.notes-window--maximized .window__handler:after {
  border-radius: 0px !important;
}

.notes-window--maximized[data-windowBackdrop="true"]:before {
  border-radius: 0px !important;
}

.notes-window--minimized {
  top: 100vh !important;
  transform: scale(0) !important;
  -webkit-transform-origin: center bottom !important;
  -moz-transform-origin: center bottom !important;
  transform-origin: center bottom !important;
  opacity: 0;
  pointer-events: none !important;
  transition: all 0.35s !important;
}

.notes-window--closed {
  display: none;
  transition: all 0.1s, top 0.1s ease-in-out, left 0.1s ease-in-out,
    transform 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out,
    z-index 0ms, box-shadow 0ms;
}

#notes {
  z-index: 100 !important;
}

.notes-window {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: hidden;
  position: absolute;
  display: block;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  transition: all 0.1s, top 0.1s ease-in-out, left 0.1s ease-in-out,
    transform 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out,
    z-index 0ms, box-shadow 0ms;
  z-index: 0;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  transform-origin: center center;
  /* -webkit-transform: none;
  -moz-transform: none; */
  /* transform: none; */
  border-radius: 10px;
  /* background: #1e1e1f; */
  min-width: 400px;
  min-height: 200px;
  overflow: hidden;
  z-index: 1;
}

.notes-window:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  z-index: 999999;
  pointer-events: none;
}

.actionbar {
  position: absolute;
  left: 0;
  top: -26px;
  /* height: 40px; */
  height: 22px;
  /* height: 3vh; */
  width: 100vw;
  z-index: 10000;
  display: flex;
  line-height: 22px;
  font-size: 14px;
  /* -webkit-backdrop-filter: blur(30px) saturate(2) brightness(0.5);
  backdrop-filter: blur(30px) saturate(2) brightness(0.5); */
  /* background: rgba(50, 50, 50, 0.5); */
  background: #e8e8ed;
  color: black;
  /* box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 rgba(0, 0, 0, 0.5); */
}

.actionbar .rightActions {
  right: 0;
}

.actionbar .leftActions {
  left: 0;
}

.actionbar .rightActions,
.actionbar .leftActions {
  position: absolute;
  display: flex;
  width: auto;
  height: 100%;
}

.actionbar .rightActions .item,
.actionbar .leftActions .item {
  position: relative;
  display: inline-flex;
  width: auto;
  height: 100%;
  margin: 0;
  color: black;
  font-size: 12px;
  /* font-weight: 500; */
  letter-spacing: 0.5px;
}

.icons8-full-battery {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIKdmlld0JveD0iMCAwIDE3MiAxNzIiCnN0eWxlPSIgZmlsbDojMjZlMDdmOyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWRhc2hhcnJheT0iIiBzdHJva2UtZGFzaG9mZnNldD0iMCIgZm9udC1mYW1pbHk9Im5vbmUiIGZvbnQtd2VpZ2h0PSJub25lIiBmb250LXNpemU9Im5vbmUiIHRleHQtYW5jaG9yPSJub25lIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6IG5vcm1hbCI+PHBhdGggZD0iTTAsMTcydi0xNzJoMTcydjE3MnoiIGZpbGw9Im5vbmUiPjwvcGF0aD48ZyBmaWxsPSIjMWZiMTQxIj48cGF0aCBkPSJNMjEuNSwyMS41djEyOWg2NC41di0zMi4yNXYtNjQuNXYtMzIuMjV6TTg2LDUzLjc1YzAsMTcuNzgwNSAxNC40Njk1LDMyLjI1IDMyLjI1LDMyLjI1YzE3Ljc4MDUsMCAzMi4yNSwtMTQuNDY5NSAzMi4yNSwtMzIuMjVjMCwtMTcuNzgwNSAtMTQuNDY5NSwtMzIuMjUgLTMyLjI1LC0zMi4yNWMtMTcuNzgwNSwwIC0zMi4yNSwxNC40Njk1IC0zMi4yNSwzMi4yNXpNMTE4LjI1LDg2Yy0xNy43ODA1LDAgLTMyLjI1LDE0LjQ2OTUgLTMyLjI1LDMyLjI1YzAsMTcuNzgwNSAxNC40Njk1LDMyLjI1IDMyLjI1LDMyLjI1YzE3Ljc4MDUsMCAzMi4yNSwtMTQuNDY5NSAzMi4yNSwtMzIuMjVjMCwtMTcuNzgwNSAtMTQuNDY5NSwtMzIuMjUgLTMyLjI1LC0zMi4yNXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==")
    50% 50% no-repeat;
  background-size: 100%;
}
.actionbar .rightActions .item:not(.sActionbarNotifications).active,
.actionbar .leftActions .item:not(.sActionbarNotifications).active {
  background: rgba(40, 40, 40, 0.4);
}

.actionbar .rightActions .item.sActionbarNotifications:active,
.actionbar .leftActions .item.sActionbarNotifications:active {
  background: rgba(40, 40, 40, 0.4);
}

.actionbar .rightActions .item.bold,
.actionbar .leftActions .item.bold {
  font-weight: 600;
}

.actionbar .rightActions .item[data-trigger="sPanelLogOptions"],
.actionbar .leftActions .item[data-trigger="sPanelLogOptions"] {
  margin-left: 18px;
}

.actionbar .rightActions .item[data-trigger="sPanelLogOptions"]:before,
.actionbar .leftActions .item[data-trigger="sPanelLogOptions"]:before {
  font-size: 10px;
  line-height: 24px;
}

.actionbar .rightActions .item[data-trigger="sPanelLogOptions"]:after,
.actionbar .leftActions .item[data-trigger="sPanelLogOptions"]:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  width: 10px;
  height: 100%;
}

.actionbar .rightActions .item[data-trigger="sPanelBluetooth"]:before,
.actionbar .leftActions .item[data-trigger="sPanelBluetooth"]:before {
  /* content: ""; */
  font-size: 16px;
  line-height: 24px;
}

.actionbar .rightActions .item[data-trigger="sPanelBluetooth"]:after,
.actionbar .leftActions .item[data-trigger="sPanelBluetooth"]:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform: translateY(-50%) translateX(-50%);
  background: #fff;
  opacity: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.35), 5px 0 0 0 white,
    5px 0 0 1px rgba(0, 0, 0, 0.25), -5px 0 0 0 white,
    -5px 0 0 1px rgba(0, 0, 0, 0.25);
}

.actionbar
  .rightActions
  .item[data-trigger="sPanelBluetooth"][data-connected="true"]:before,
.actionbar
  .leftActions
  .item[data-trigger="sPanelBluetooth"][data-connected="true"]:before {
  opacity: 0.75;
}

.actionbar
  .rightActions
  .item[data-trigger="sPanelBluetooth"][data-connected="true"]:after,
.actionbar
  .leftActions
  .item[data-trigger="sPanelBluetooth"][data-connected="true"]:after {
  opacity: 1;
}

.actionbar .rightActions .item[data-trigger="sPanelPower"],
.actionbar .leftActions .item[data-trigger="sPanelPower"] {
  padding-right: 36px;
}

.actionbar
  .rightActions
  .item[data-trigger="sPanelPower"][sPowerShowPercentage="true"],
.actionbar
  .leftActions
  .item[data-trigger="sPanelPower"][sPowerShowPercentage="true"] {
  padding-right: 46px;
}

.actionbar
  .rightActions
  .item[data-trigger="sPanelPower"][sPowerShowPercentage="true"]:before,
.actionbar
  .leftActions
  .item[data-trigger="sPanelPower"][sPowerShowPercentage="true"]:before {
  content: attr(data-percentage) "%";
}

.actionbar .rightActions .item[data-trigger="sPanelPower"] i,
.actionbar .leftActions .item[data-trigger="sPanelPower"] i {
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
  width: 26px;
  height: 12px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px white;
}

.actionbar .rightActions .item[data-trigger="sPanelPower"] i:after,
.actionbar .leftActions .item[data-trigger="sPanelPower"] i:after {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  left: 2px;
  width: 30%;
  max-width: calc(100% - 4px);
  height: 8px;
  background: white;
  border-radius: 1px;
}

.actionbar .rightActions .item[data-trigger="sPanelPower"] i:before,
.actionbar .leftActions .item[data-trigger="sPanelPower"] i:before {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  right: -3px;
  width: 2px;
  max-width: calc(100% - 4px);
  height: 4px;
  background: white;
  border-radius: 0 100% 100% 0;
}

.actionbar.hide {
  top: -26px;
  pointer-events: none;
  opacity: 0;
  box-shadow: none;
}

.side-panel-wrapper {
  width: 350px;
  /* border: 1px solid #0000004d;
  background: #f3f3f34d;
  backdrop-filter: blur(30px);
  box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%), 0px 0px 1px 0px rgb(0 0 0 / 30%); */
  position: absolute;
  right: -40%;
  border-radius: 20px;
  top: 25px;
  z-index: 1000;
}

.control-item {
  /* background: rgba(255, 255, 255, 0.7); */
  box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%), 0px 0px 1px 0px rgb(0 0 0 / 30%);
  color: white;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px rgba(0, 0, 0, 0.55);
}

.knob {
  cursor: url("./assets/svgs/pointer.svg"), auto;
  

  /* border: 1px solid #4040404d;
  background: rgb(0 0 0 / 0.2);
  backdrop-filter: blur(30px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.1); */
}

progress::-moz-progress-bar {
  background: white;
}
progress::-webkit-progress-value {
  background: white;
}
progress {
  color: white;
}

.active-tab {
  background-color: white;
}

.inactive-tab {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%), 0px 0px 1px 0px rgb(0 0 0 / 30%);
}

.control-item-hov:hover svg {
  display: block;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
  height: 21px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 21px;
  background: #565555;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 21px;
  background: #565555;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 21px;
  width: 21px;
  background-color: #f1f0f0;
  border-radius: 50%;
  border: none;
  box-shadow: -407px 0 0 400px #fff;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 21px;
  width: 21px;
  background-color: #f1f0f0;
  border-radius: 50%;
  /* border: 1px solid #565555; */
  border: none;
  box-shadow: -407px 0 0 400px #fff;
}

.mobile-dock {
  position: absolute;
  bottom: 6px;
  left: 6px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(36, 36, 36, 0.513);
  border-radius: 20px;
  z-index: 999;
  margin-top: 6.6rem;
  
}
.mobile-dock-inner {
  background-color: rgba(36, 36, 36, 0.513);

}

.mobile-dock-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 1rem;
  gap: 1rem;
}

.mobile-bg {
  object-fit: cover;
  background: url(./assets/images/mobile-wallpaper.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* -webkit-filter: blur(20px); */
}

td {
  padding: 0 15px !important;
}

.notes-gradient {
  background: linear-gradient(to bottom, #ffdb4c, #ffcd02);
  /* You may need to adjust the direction ('to bottom', 'to top', 'to right', 'to left', etc.) depending on your needs */
}
@media screen and (max-width: 599px) {
  .mobile-dock-wrapper {
    max-width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .mobile-dock-wrapper {
    max-width: 550px;
    width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 500px) {
  .app-icons {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  /* .mobile-dock-wrapper {
    width: 600px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
  } */
}


.not{
  -webkit-backdrop-filter: blur(0.5rem);
  backdrop-filter: blur(0.5rem);
  background-color: ;
}