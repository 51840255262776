@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
    font-family: 'SF-Pro';
    src: url('./assets/fonts/SF-Pro-Display-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  

  /* *{
    border: 1px solid red
  } */